import { inject } from '@angular/core';
import { ApiService } from '@app/core/services';
import { Conversation } from '@app/core/types';
import { filterSuccess } from '@app/core/utils';
import {
	patchState,
	signalStore,
	withHooks,
	withMethods,
	withState,
} from '@ngrx/signals';
import {
	addEntity,
	removeEntity,
	updateEntity,
	withEntities,
} from '@ngrx/signals/entities';
import { debounceTime } from 'rxjs';

// @ts-ignore
export const ConversationsStore = signalStore(
	{ providedIn: 'root' },
	withEntities<Conversation>(),
	withMethods(store => ({
		getConversation(id: string): Conversation | undefined {
			return store.ids().includes(id) ? store.entityMap()[id] : undefined;
		},
		checkConversation(id: string): boolean {
			return store.ids().includes(id);
		},
		addConversation(newConversation: Conversation): void {
			patchState(store, addEntity(newConversation));
		},
		updateConversation(id: string, data: Partial<Conversation>): void {
			patchState(store, updateEntity({ id, changes: data }));
		},
		removeConversation(id: string): void {
			patchState(store, removeEntity(id));
		},
	})),
	withHooks(store => {
		const api = inject(ApiService);
		return {
			onInit() {
				api
					.lastChats()
					.pipe(debounceTime(1000), filterSuccess<Conversation[]>())
					.subscribe(response => {
						response.data.forEach(chat => store.addConversation(chat));
					});
			},
		};
	})
);

export type AppState = {
	isFetching: boolean;
	isSummiting: boolean;
};

const initialAppState: AppState = {
	isFetching: false,
	isSummiting: false,
};

export const AppStore = signalStore(
	{ providedIn: 'root' },
	withState(initialAppState),
	withMethods(store => ({
		setIsFetching(isFetching: boolean): void {
			patchState(store, { isFetching });
		},
		setIsSummiting(isSummiting: boolean): void {
			patchState(store, { isSummiting });
		},
	}))
);
